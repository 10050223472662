import React from "react";
import Content from '../views/Driver/Main';

export default ({location}) => <Content location={location} seo={{
  title: "Connected car application for every driver",
  lang: 'en',
  description: "Keep a better overview of your vehicle condition with a mobile app. Manage car-related tasks, e.g., driving logbook. Connect directly to the mechanic.",
  meta: [{
    name: 'keywords',
    content: 'car app',
  }]
}}/>